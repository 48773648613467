<template>
  <div class="item_list">
    <van-nav-bar
      title="收益明细"
      left-arrow
      safe-area-inset-top
      @click-left="navBack"
    />
    <div class="content">
      <div class="class_totalincome">
        <div class="class_totalincome_info">
          累计佣金
        </div>
        <div class="class_totalincome_value">
          {{ '￥' + String(retrunfeec ? retrunfeec.toFixed(2) : '0.00') }}
        </div>
      </div>
      <income-card
        ref="icard"
        :d1="
          String(
            UserProfile.commission_ing
              ? UserProfile.commission_ing.toFixed(2)
              : '0.00'
          )
        "
        :d2="
          String(
            UserProfile.team_building_fee
              ? UserProfile.team_building_fee.toFixed(2)
              : '0.00'
          )
        "
        :d3="
          String(
            UserProfile.return_commision
              ? UserProfile.return_commision.toFixed(2)
              : '0.00'
          )
        "
        :d4="
          String(
            UserProfile.commission_wait
              ? UserProfile.commission_wait.toFixed(2)
              : '0.00'
          )
        "
        class="class_incomecard"
      />
      <van-tabs
        v-model="tabActive"
        sticky
        animated
        swipeable
        :lazy-render="false"
        @change="tabchange"
      >
        <van-tab
          v-for="(tab, tabIndex) in tabsItem"
          :key="tab.type"
          :title="tab.name"
        >
          <!-- <div class="fixheight" :class="tabIndex === 1 ? 'fixpaddingbtn' : ''"> -->
          <div>
            <van-pull-refresh
              :id="`toplist${tabIndex}`"
              :ref="`tabpage${tabIndex}`"
              v-model="refreshing"
              class="fixheight"
              :class="tabIndex === 1 ? 'fixpaddingbtn' : ''"
              @refresh="onRefresh(tabIndex)"
            >
              <van-list
                :ref="'tabScrolls' + tabIndex"
                v-model="tab.loading"
                :finished="tab.finished"
                finished-text="没有更多了"
                :error.sync="tab.error"
                error-text="请求失败，点击重新加载"
                offset="300"
                @load="onListLoad(tabIndex)"
              >
                <template v-if="tabActive === 0">
                  <van-cell
                    v-for="item in tab.items"
                    :key="item.id"
                    center
                    :title="`单号：${item.order_sn}`"
                    :label="item.time"
                    :value="`佣金: ￥${item.money}`"
                  >
                    <template #extra>
                      <van-icon
                        class="class_item_icon"
                        :style="{ color: '#FCB844' }"
                        class-prefix="my-icon"
                        name="coin"
                      />
                    </template>
                  </van-cell>
                </template>
                <template v-else>
                  <van-cell
                    v-for="(item, index) in tab.items"
                    :key="index"
                    center
                    :label="`申请日期：${item.time}`"
                    :title="item.money === '0.00' ? '-' : `￥${item.money}`"
                    :value="stringfystatus(item.status)"
                    @click="onViewDetal(item)"
                  >
                    <template #extra>
                      <van-icon
                        class="class_item_icon"
                        :style="{ color: stringfystatusColor(item.status) }"
                        :class-prefix="stringfystatusCp(item.status)"
                        :name="stringfystatusName(item.status)"
                      />
                    </template>
                  </van-cell>
                </template>
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
      </van-tabs>
      <transition name="fade">
        <van-button
          v-if="tabActive === 1 && showBtn"
          icon="plus"
          type="primary"
          class="class_apply_btn"
          @click="onApply"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import {
  List,
  Cell,
  CellGroup,
  Field,
  Form,
  Search,
  Tab,
  Tabs,
  Button,
  PullRefresh,
  NavBar
} from 'vant'
import ItemCardGoods from '../../../components/item-card-goods'
import { incomeDetail, teamBuildLogList } from '@/api/home'
import asyncwait from '@/mixins/asyncwait'
import IncomeCard from './income-card'
import { mapState } from 'vuex'
import _ from 'lodash'
// import { debounce, throttle } from 'lodash/function'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [List.name]: List,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Tab.name]: Tab,
    [NavBar.name]: NavBar,
    [PullRefresh.name]: PullRefresh,
    [Tabs.name]: Tabs,
    [Form.name]: Form,
    [Search.name]: Search,
    [CellGroup.name]: CellGroup,
    [ItemCardGoods.name]: ItemCardGoods,
    [IncomeCard.name]: IncomeCard
  },
  mixins: [asyncwait],
  data() {
    return {
      transitionName: 'van-slide-up',
      searchVal: '',
      editable: false,
      refreshing: false,
      scrollTopold: 0,
      showBtn: true,
      tabActive: 0,
      sort: this.sortVal,
      totalincome: '0',
      scrollTop1: 0,
      incomedetail: { d1: 253.01, d2: 253.02, d3: 253.03, d4: 253.04 },
      tabsItem: [
        {
          name: '收益明细',
          sort: '',
          loading: false,
          finished: false,
          error: false,
          items: [],
          pageIndex: 1
        },
        {
          name: '申请记录',
          sort: '',
          loading: false,
          finished: false,
          error: false,
          items: [],
          pageIndex: 1
        }
      ],
      pattern: /^1[3456789]\d{9}$/
    }
  },
  computed: {
    ...mapState(['UserProfile']),
    retrunfeec() {
      return (
        this.UserProfile.return_commision + this.UserProfile.commission_wait
      )
    }
  },
  created() {},
  // beforeRouteEnter(to, from, next) {
  //   console.log('beforeRouteEnter')
  //   console.log(from.name)
  //   // if (from.name === 'applyEntity') {
  //   //   to.meta.isapply = true
  //   // }
  //   // if (from.name === 'costEntity') {
  //   //   to.meta.iscost = true
  //   // }
  //   next()
  // },
  mounted() {
    // console.log('income mounted', this.$refs['tabpage1'][0])
    let elem = this.$refs['tabpage1'][0].$el
    this.throttleLoad = _.throttle(e => {
      this.scrollHander(e)
    }, 77)
    this.throttleLoadL = _.debounce(() => {
      this.scrollHanderL()
    }, 2077)
    elem.addEventListener('scroll', this.throttleLoad)
    elem.addEventListener('scroll', this.throttleLoadL)
  },
  activated() {
    console.log('income activated')
    if (this.$route.meta.isApply) {
      console.log('activated isApply onRefresh')
      // this.onRefresh(1)
      this.$route.meta.isApply = false
    }
    let elem = document.getElementById('toplist1')
    if (elem) elem.scrollTop = this.scrollTop1
  },
  deactivated() {
    console.log('income deactivated')
    let elem = document.getElementById('toplist1')
    this.scrollTop1 = elem.scrollTop
  },
  methods: {
    toggleFilterModal() {},
    tabchange(e) {
      if (e === 1) this.showBtn = true
    },
    scrollHander(e) {
      if (this.scrollTopold > e.target.scrollTop) this.showBtn = true
      else this.showBtn = false
      this.scrollTopold = e.target.scrollTop
    },
    scrollHanderL() {
      console.log('scrollHanderL')
      this.showBtn = true
    },
    navBack() {
      this.$router.go(-1)
    },
    search() {
      console.log('search')
    },
    onApply() {
      console.log('onApply')
      this.$router.push({ name: 'applyEntity' })
    },
    onViewDetal(item) {
      console.log('onViewDetal')
      // if (this.tabsItem[1].loading === true) return
      this.$router.push({
        name: 'detailEntity',
        query: { id: `${item.id}` },
        params: { id: `${item.id}`, detail: item }
      })
    },
    onSubmitInvoice() {
      console.log('onSubmitInvoice')
      this.$router.push({ name: 'costEntity', params: { id: '133' } })
    },
    stringfystatus(status) {
      switch (status) {
        case 0:
          return '审核中'
        case 1:
          return '提交发票'
        case 2:
          return '资料已提交'
        case 3:
          return '完成'
        case 8:
          return '审核失败'
        case 9:
          return '资料审核失败'
        default:
          return '审核中'
      }
    },
    stringfystatusColor(status) {
      switch (status) {
        case 0:
          return '#FCB844'
        case 8:
          return '#FF796C'
        case 9:
          return '#FF796C'
        default:
          return '#30AE64'
      }
    },
    stringfystatusName(status) {
      switch (status) {
        case 0:
          return 'underway'
        case 1:
          return 'add'
        case 2:
          return 'bill'
        case 3:
          return 'checked'
        default:
          return 'clear'
      }
    },
    stringfystatusCp(status) {
      switch (status) {
        case 2:
          return 'my-icon'
        default:
          return 'van-icon'
      }
    },
    async onRefresh(tabIndex) {
      // 清空列表数据
      this.tabsItem[tabIndex].items = []
      this.tabsItem[tabIndex].finished = false
      this.refreshing = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.tabsItem[tabIndex].loading = true
      this.tabsItem[tabIndex].pageIndex = 1
      await this.onListLoad(tabIndex)
    },
    async onListLoad(tabIndex) {
      console.log(`callload${tabIndex}`)
      await this.asyncWait(300)
      if (tabIndex === 0) {
        try {
          const res = await incomeDetail({
            pageIndex: this.tabsItem[0].pageIndex,
            pageSize: 5
          })
          if (res.status !== 1) throw res.msg
          this.tabsItem[0].pageIndex++
          this.tabsItem[0].items = [...this.tabsItem[0].items, ...res.data]
          this.tabsItem[0].loading = false
          if (res.data.length < 5) {
            this.tabsItem[0].finished = true
          }
          console.log(res)
          console.log('incomeDetail cp')
        } catch (err) {
          this.tabsItem[0].loading = false
          this.tabsItem[0].error = true
          console.log(err)
        }
      } else {
        try {
          const res = await teamBuildLogList({
            pageIndex: this.tabsItem[1].pageIndex,
            pageSize: 10
          })
          if (res.status !== 1) throw res.msg
          this.tabsItem[1].pageIndex++
          this.tabsItem[1].items = [...this.tabsItem[1].items, ...res.data]
          this.tabsItem[1].loading = false
          if (res.data.length < 5) {
            this.tabsItem[1].finished = true
          }
          console.log(res)
          console.log('teamBuildLogList cp')
        } catch (err) {
          this.tabsItem[1].loading = false
          this.tabsItem[1].error = true
          console.log(err)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
  }
  .content_zone {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .class_totalincome {
    display: flex;
    flex: 0 0 90px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90px;
    color: #ffffff;
    background-color: #30ae64;
    &_info {
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 400;
    }
    &_value {
      font-family: Source Han Sans CN;
      font-size: 36px;
      font-weight: bold;
    }
  }
  .class_incomecard {
    background-color: #30ae64;
  }
  .info_item {
    position: sticky;
    top: 0;
    flex: 0 0 44px;
  }
  .class_item_card {
    width: calc(100vw - 10px);
    margin: 5px;
    &_btn {
      color: #999999;
      background-color: #f5f5f5;
      border: 1px dashed #bababa;
    }
  }
  .class_apply_btn {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    filter: drop-shadow(0 0 2px #666666);
    border-radius: 25px;
  }
}
.fixheight {
  height: calc(100vh - 46px - 44px - 150px);
  // height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.class_item_icon {
  margin-left: 5px;
  font-size: 18px;
}
.fixpaddingbtn {
  padding-bottom: 44px;
}

.mt-10 {
  margin-top: 10px;
}
.m-16 {
  margin: 16px;
}
.mt-30 {
  margin-top: 30px;
}

.item_list .van-tab--disabled {
  color: #000000;
}
.van-cell__title {
  flex: 2;
}
.van-cell__value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fb301c;
}
.class_datetimepicker {
  border-radius: 5px;
}
.fade-enter-active {
  // transition: opacity 0.5s, transform 0.2s 0.5s;
  transition: all 0.5s;
}
.fade-leave-active {
  // transition: opacity 0.5s 0.2s, transform 0.2s;
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  transform: translateX(100px);
  // opacity: 0;
  // transform: rotate(45deg);
}
</style>
