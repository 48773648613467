<template>
  <div class="brokerage_card_warp">
    <div class="brokerage_card_warp_item">
      <div class="brokerage_card_warp_item_main">
        {{ `￥${d1}` }}
      </div>
      <div class="brokerage_card_warp_item_sub">
        实时佣金
      </div>
    </div>
    <div class="brokerage_card_warp_item">
      <div class="brokerage_card_warp_item_main">
        {{ `￥${d2}` }}
      </div>
      <div class="brokerage_card_warp_item_sub">
        本月团建金额
      </div>
    </div>
    <div class="brokerage_card_warp_item">
      <div class="brokerage_card_warp_item_main">
        {{ `￥${d3}` }}
      </div>
      <div class="brokerage_card_warp_item_sub">
        已结算
      </div>
    </div>
    <div class="brokerage_card_warp_item">
      <div class="brokerage_card_warp_item_main">
        {{ `￥${d4}` }}
      </div>
      <div class="brokerage_card_warp_item_sub">
        待结算佣金
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IncomeCard',
  props: {
    d1: {
      type: String,
      default: '0'
    },
    d2: {
      type: String,
      default: '0'
    },
    d3: {
      type: String,
      default: '0'
    },
    d4: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {}
  },
  activated() {
    console.log('income card activated')
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.brokerage_card_warp {
  display: flex;
  flex: '0' 0 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 60px;
}
.brokerage_card_warp_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: Source Han Sans CN;
  font-weight: 400;
  &_main {
    font-size: @font-size-normal;
    color: #ffffff;
  }
  &_sub {
    font-size: @font-size-normal;
    color: #ffffff;
  }
}
</style>
